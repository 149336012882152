<template>
  <div id="registerPage">
    <!-- <el-button class="loginBtn" type="text" @click="$router.push({ name: 'Login' })">Sign in</el-button> -->
    <div id="registerBox">
      <!-- Left side marketing message -->
      <div class="leftBox" v-if="$mq != 'mobile'">
        <img class="registerImge" v-if="!isRegisterViaShopify" src="https://static.growlytics.in/dashboard-assets/assets/img/register/newregisterimage.png" />
        <div class="shopify-integration" v-else>
          <img class="img-shopify-link" src="https://static.growlytics.in/dashboard-assets/assets/img/register/shopify-link.png" />
          <div class="header-1">Your store is now connected with Growlytics.</div>
          <div class="header-2">Complete your profile to get started.</div>
        </div>
      </div>

      <!-- Right side login box -->
      <div class="loginSection">
        <div class="loginBox">
          <!-- Logo -->
          <div class="logoContainer">
            <!-- <img class="logoIcon" src="https://static.growlytics.in/dashboard-assets/assets/img/logo/logo_dark_hr.png" /> -->
            <img class="logoIcon" src="@/assets/images/logo-full.png" />
            <br />
            <template v-if="isRegisterViaShopify"> Complete Your Profile </template>
            <template v-else> Create Account </template>
          </div>

          <div v-if="errorMsg != ''" class="errorDiv">
            {{ errorMsg }}
          </div>

          <!-- Register Form -->
          <el-form ref="registerForm" class="registerForm" :model="registerForm" :rules="validationRules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="First Name" prop="first_name" style="padding-bottom: 10px">
                  <el-input id="nameInput" type="text" v-model="registerForm.first_name" required></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Last Name" prop="last_name" style="padding-bottom: 10px">
                  <el-input id="nameInput" v-model="registerForm.last_name" required></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Company Name" prop="company" style="padding-bottom: 10px">
                  <el-input id="nameInput" type="text" v-model="registerForm.company" required></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Mobile Number" prop="mobile" style="padding-bottom: 10px">
                  <el-input type="number" id="nameInput" v-model="registerForm.mobile" required></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="Email Id" prop="email" style="padding-bottom: 10px">
              <el-input :disabled="isRegisterViaShopify" type="email" v-model="registerForm.email" required></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password" style="padding-bottom: 10px">
              <el-input id="passwordInput" type="password" v-model="registerForm.password" required></el-input>
            </el-form-item>
            <el-form-item>
              <vue-recaptcha class="captchaContainer" ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" sitekey="6LcyoKQUAAAAAHHZj49M3FWALigIvnXY5XILKq_l"> </vue-recaptcha>
            </el-form-item>
            <div class="buttonContainer">
              <el-button type="primary" class="el-button el-button--primary submitBtn" :loading="registerProcessing" @click="onRegisterSubmit">
                <template v-if="!isRegisterViaShopify">Create Account</template>
                <template v-else>Complete Your Profile</template>
              </el-button>
              <template v-if="!isRegisterViaShopify">
                &nbsp; or
                <el-button @click="$router.push({ name: 'Login' })">Sign In</el-button>
              </template>
              <!--<el-button @click="$router.push({ name: 'Login' });">Login</el-button> -->
            </div>

            <div class="legalInfo" style="font-size: 10px; text-align: center; margin-top: 20px; line-height: 13px">
              By clicking register button, you agree to our
              <a href="http://growlytics.in/terms-of-use/" target="_blank">Terms of Service</a>
              and have read and acknowledge our
              <a href="http://growlytics.in/privacy-policy/" target="_blank">Privacy Policy</a>.
            </div>
          </el-form>
        </div>
      </div>

      <!-- Right side login box -->
      <div class="endSpacingSection" v-if="$mq != 'mobile'"></div>
    </div>
  </div>
</template>
<script>
import RegisterComponent from './registerComponent';
export default RegisterComponent;
</script>

<style lang="scss" src="./register.scss"></style>
