import VueRecaptcha from 'vue-recaptcha';
import { VueTyper } from 'vue-typer';
import authService from '../../../services/auth';

export default {
  name: 'register',
  components: { VueRecaptcha, VueTyper },
  data() {
    return {
      // Shopify based login vars
      isRegisterViaShopify: false,
      shopifyAccessToken: null,
      shopifyStoreName: null,
      shopifyDomain: null,

      errorMsg: '',
      registerProcessing: false,
      registerForm: null,
      validationRules: null
    };
  },
  computed: {},
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.registerForm.recaptchaToken = '';
    },

    async onRegisterSubmit() {
      try {
        // Cancel parent event
        // Validate Form
        let isValidData = await this.validateForm(this.$refs.registerForm);
        if (!isValidData) return false;

        // Validate Captcha
        if (!this.registerForm.recaptchaToken) {
          this.errorToast('Captcha Verification Required.');
          return;
        }

        this.registerProcessing = true;
        var params = {
          first_name: this.registerForm.first_name,
          last_name: this.registerForm.last_name,
          mobile: this.registerForm.mobile,
          company: this.registerForm.company,
          email: this.registerForm.email,
          password: this.registerForm.password,
          recaptchaToken: this.registerForm.recaptchaToken
        };

        // Put shopify info if provied.
        params.isShopifyRegisteration = this.isRegisterViaShopify;
        if (this.isRegisterViaShopify) {
          params.shopify = {
            accessToken: this.shopifyAccessToken,
            domain: this.shopifyDomain,
            name: this.shopifyStoreName
          };
        }

        let result = await authService.register(params);
        if (result.data.success) {
          // Track register event
          this.marketingTrackEvent('Signed Up', result.data.data);
          this.handleNewSession(result.data);
        } else {
          this.registerSuccess = false;
          this.onCaptchaExpired();
          this.errorMsg = result.data.message;
          this.registerProcessing = false;
        }
      } catch (error) {
        //Something is wrong, please contact support.
        this.reportError(error);
        this.onCaptchaExpired();
        this.errorToast(`Something's not right. Please contact support team.`);
        this.registerProcessing = false;
      }

      return false;
    },

    onCaptchaVerified(recaptchaToken) {
      this.registerForm.recaptchaToken = recaptchaToken;
    }
  },

  created() {
    // Read form and rules from mixin
    this.registerForm = this.getRegisterationForm();
    this.validationRules = this.getRegisterationFormRules();
  },

  mounted() {
    // check for shopify based registeration
    if (this.$route.query.shopifyToken) {
      this.isRegisterViaShopify = true;
      this.shopifyStoreName = this.$route.query.store;
      this.shopifyAccessToken = this.$route.query.shopifyToken;
      this.shopifyDomain = this.$route.query.domain;
      this.registerForm.email = this.$route.query.email;
    }

    // Check if email id is predefined in url
    if (this.$route.query.email) {
      this.registerForm.email = this.$route.query.email;
    }
  }
};
